import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import NavsList from "./NavsList";
import "../Style/audioBook.css";
import { AppContext } from '../App';
import Comment from "./Comment";

export default function AudioBook(){
    const {playerInfo} = useContext(AppContext);
    const [,setPlayer] = playerInfo;
    const [firstLoading,setFirstLoading] = useState(false);
    const [activeIndex,setActiveIndex] = useState(0);
    const [data,setData] = useState({
        cover:"/image/podcastImg.jpg",
        name : "کتاب صوتی آموزش مدیتیشن",
        shortDesc : 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود',
        playList : [
            {
                url:"/podcast/1",
                title : "قسمت اول",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 50,
                price: 100000
            },
            {
                url:"/podcast/1",
                title : "قسمت دوم",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 0,
                price: 0
            },
            {
                url:"/podcast/1",
                title : "قسمت سوم",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 10,
                price: 200000
            },
            {
                url:"/podcast/1",
                title : "قسمت چهارم",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 0,
                price: 200000
            },
            {
                url:"/podcast/1",
                title : "قسمت پنجم",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 10,
                price: 200000
            },
            {
                url:"/podcast/1",
                title : "قسمت ششم",
                cover : "/image/podcastImg.jpg",
                duration : "۱۰:۰۰",
                src:"https://nicmusic.musicmelnet.com/nicmusic/032/061/bikalam%20molayem%20001.mp3",
                discount : 10,
                price: 200000
            },
        ],
        hasPermission : true,
        totalRate : 5,
        totalRateNumber : 100 ,
        commentsList : [],
        commentsTotalNumber : 10,
        commentsList : [
            {
                userName : "محمد کریمی",
                rate : 5,
                comment : "عالی بود ممنون از تیم هوشمند چکیده ممنون از جناب محمدپور که با استادی ، مطالب مهم رو با هنر و صداشون متمایز می کردن خواهش می کنم در چکیده کردن کتابها امانت و حق مطلب اصلی رو ادا کنین",
                adminAnswer : "چکیدا عالی هستی فقط دو مورد ۱..اگه قابلیت مارک کردن سطرهای مهمو اضافه میکردی عالی بود"
            },
            {
                userName : "محمد کریمی",
                rate : 4,
                comment : "عالی بود ممنون از تیم هوشمند چکیده ممنون از جناب محمدپور که با استادی ، مطالب مهم رو با هنر و صداشون متمایز می کردن خواهش می کنم در چکیده کردن کتابها امانت و حق مطلب اصلی رو ادا کنین",
                adminAnswer : ""
            },
        ],
    });
    const [activeAudio,setActiveAudio] = useState(null);
    useEffect(() => {
        setPlayer(null);
    }, []);
    return <>
        {
            firstLoading ? <></> : 
            <>
                <NavsList data={[{name:data.name,link:""}]} />
                <section className="audioBookInfoSection grid container">
                    <img src={data.cover} alt={data.name} className="audioImg" draggable="false" width="100%"/>
                    <div className="flex flexCol audioBookInfoDiv">
                        <h1 className="faFont textSize16 textClr3 audioBookTitle">
                            {
                                data.name
                            }
                        </h1>
                        <p className="faFont textSize11 textClr7 justifyText audioBookShortDesc">
                            {
                                data.shortDesc
                            }
                        </p>
                        <div className="flex audioPlayActionDiv">
                            <button className="nextAudioBtn flex flexCenter textClr5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="m6 18 8.5-6L6 6v12zM16 6v12h2V6h-2z"></path></svg>
                                <span className="hideText">next</span>
                            </button>
                            <button className="flex flexCenter audioPlayBtn textClr5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path></svg>
                                <span className="hideText">play</span>
                            </button>
                            <button className="prevAudioBtn flex flexCenter textClr5">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M6 6h2v12H6zm3.5 6 8.5 6V6z"></path></svg>
                                <span className="hideText">prev</span>
                            </button>
                            <div className="flex alignCenter justifyBetween audioDurationDiv">
                                <div className="audioProgressDiv rel flex flexCol">
                                    <div className="audioProgressItem"></div>
                                </div>
                                <p className="faFont textSize9 textClr3 audioPassedTime">04:06</p>
                                <p className="faFont textSize9 textClr3 audioTotalTime">12:20</p>
                            </div>
                        </div>
                        <div className="flex flexCol audioPlayListDiv">
                            <h3 className="faFont textSize12 textClr3 flex alignCenter gap10">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="25" width="25" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 10h11v2H3zM3 6h11v2H3zM3 14h7v2H3zM16 13v8l6-4z"></path></svg>
                                لیست اپیزود ها
                            </h3>
                            <div className="flex flexCol audioPlayList">
                                {
                                    data.playList.map((item,index)=>{
                                        return <div className="audioPlayItem grid" key={index}>
                                            <img src={item.cover} alt={item.title} className="audioItemImg" draggable="false" width={"100%"}/>
                                            <button className="faFont textSize1 textClr3 audioPlayItemName">
                                                {
                                                    item.title
                                                }
                                            </button>
                                            <button className="flex flexCenter audioPlayItemPlayBtn">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 384 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path></svg>
                                                <span className="hideText">play</span>
                                            </button>
                                            <button className="flex flexCenter audioPlayItemDownloadBtn">
                                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="23" width="23" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z"></path></svg>
                                                <span className="hideText">download</span>
                                            </button>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Comment data={{totalRate:data.totalRate , totalRateNumber : data.totalRateNumber ,comments : data.commentsList ,totalComments : data.commentsTotalNumber}} />
            </>
        }
    </>
}