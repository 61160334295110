import React ,{useEffect, useRef, useState} from "react";
import L from "leaflet";
import "../Style/searchSchool.css";
import ProfileSubLoading from "./ProfileSubLoading";
import { Link } from "react-router-dom";
import "leaflet/dist/leaflet.css";

export default function SearchSchool(){
  const mapRef = useRef(null);
  const firstTime = useRef(true);
  const [firstLoad,setFirstLoad]= useState(true);
  const [showSchool,setShowSchool] = useState(null);
  useEffect(() => {
    const locationList = [{x :35.70,y :51.33,
      name :"سعادت اباد",
      img : "/image/teatre.webp",
      link : "/school/id"
    },{x :35.708795,y :51.379955,
      name :"سعادت اباد",
      img : "/image/teatre.webp",
      link : "/school/id"
    },{x :35.648697,y :51.314209,
      name :"سعادت اباد",
      img : "/image/teatre.webp",
      link : "/school/id"
    },{x :35.705371,y :51.177080,
      name :"سعادت اباد",
      img : "/image/teatre.webp",
      link : "/school/id"
    },];
    setTimeout(() => {
      if(firstTime.current){
        firstTime.current = false;
          const map = L.map("map").setView([35.70, 51.33], 17);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        
        var myIcon = L.icon({
          iconUrl: '/image/mapIcon.webp',
          iconAnchor: null,
          popupAnchor: null,
          iconSize: new L.Point(60, 60),
        });
        locationList.forEach(item=>{
          var marker = L.marker([item.x, item.y], {icon: myIcon}).addTo(map);
          marker.on('click', function() {
            setShowSchool(item);
          });
        });
       setFirstLoad(false);
      }
    }, 2000);
  }, []);
    return (
      <section className="flex flexCol mapSection rel">
        <div className="centerItem flex flexCenter textSize1">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 2C8.13 2 5 5.13 5 9c0 1.74.5 3.37 1.41 4.84.95 1.54 2.2 2.86 3.16 4.4.47.75.81 1.45 1.17 2.26.26.55.47 1.5 1.26 1.5s1-.95 1.25-1.5c.37-.81.7-1.51 1.17-2.26.96-1.53 2.21-2.85 3.16-4.4C18.5 12.37 19 10.74 19 9c0-3.87-3.13-7-7-7zm0 9.75a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5z"></path></svg>
          </div>
        <div id="map" style={{ height: "100dvh" }}></div>
        <div className="flex alignCenter justifyBetween container mapHeader">
          <h1 className="faFont textSize12 textClr3">
            آموزشگاه های اطراف من
          </h1>
          <Link to={"/"} className="flex flexCenter backToHome textSize1 textClr3">
            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
            <span className="hideText">back</span>
          </Link>
        </div>
          {
            showSchool && <div className="flex flexCol popUpSchoolDiv">
              <div className="flex alignCenter justifyBetween">
                <p className="faFont textSize1 textClr3">
                  مشاهده جزئیات آموزشگاه
                </p>
                <button className="flex flexCenter textSize1 textClr5" onClick={()=>setShowSchool(null)}>
                  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="20" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49z"></path></svg>
                  <span className="hideText">close</span>
                </button>
              </div>
              <div className="popUpSchoolContent grid">
                  <Link to={showSchool.link} draggable="false">
                    <img src={showSchool.img} alt={showSchool.name} className="popUpSchoolImg" width="100%" draggable="false"/>
                  </Link>
                  <div className="flex flexCol popUpSchoolInfoDiv">
                    <Link to={showSchool.link} className="faFont textSize11 textClr3" draggable="false">
                    آموزشگاه {
                        showSchool.name
                      }
                    </Link>
                    <Link to={showSchool.link} className="faFont textSize9 textClr3 flex alignCenter seeSchoolLink" draggable="false">
                      صفحه ی آموزشگاه
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="15" width="15" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M15.41 16.59 10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path></svg>
                    </Link>
                  </div>
              </div>
          </div>
          }
        {
          firstLoad && <ProfileSubLoading/>
        }
      </section>
      );
}
