import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from '../App';
import "../Style/player.css";
export default function Player(){
    const {playerInfo} = useContext(AppContext);
    const [player,setPlayer] = playerInfo;
    const [mode ,setMode] = useState("");
    const customMusicPlayer = useRef(null);
    const passedTime = useRef(null);
    const padCastTotalTime = useRef(null);
    const haspadCastTotalTime = useRef(false);
    const podcastProgress = useRef(false);
    const podcastProgressBar = useRef(false);
    const volumeDiv = useRef(false);
    const volumeProgress = useRef(false);
    const [repeatMode,setRepeatMode] = useState(false);
    const [currentPodcastIndex,setCurrentPodcastIndex] = useState(null);
    const [isPlaying,setIsPlaying] = useState(false);
    useEffect(() => {
        if(player){
            generatePlayer();
            haspadCastTotalTime.current = false;
            podcastProgressBar.current.style.width = `0px`;
            playAudio();
        }else if(customMusicPlayer.current){
            customMusicPlayer.current.pause();
            setPlayer(null);
            setIsPlaying(false);
        }
    }, [player]);
    function generatePlayer(){
        customMusicPlayer.current = document.getElementById("customMusicPlayer");
        padCastTotalTime.current = document.querySelector(".padCastTotalTime");
        passedTime.current = document.querySelector(".passedTime");
        podcastProgress.current = document.querySelector(".podcastProgress");
        podcastProgressBar.current = document.querySelector(".podcastProgressBar");
        volumeDiv.current = document.querySelector(".volumeDiv");
        volumeProgress.current = document.querySelector(".volumeProgress");
        customMusicPlayer.current.addEventListener("timeupdate", updateTimeElapsed);
        podcastProgress.current.addEventListener("click", (e) => changeTimeHandler(e));
        volumeDiv.current.addEventListener("click", (e) => changeVolumeHandler(e));
        customMusicPlayer.current.addEventListener("ended",musicEndHandler);
    }
    function playAudio(){
        if(!customMusicPlayer.current){
            return;
        }
        padCastTotalTime.current.innerText = "00 : 00";
        passedTime.current.innerText = "00 : 00";
        setIsPlaying(true);
    }
    function updateTimeElapsed(){
        if(customMusicPlayer.current.duration && !haspadCastTotalTime.current){
            const time = formatTime(Math.round(customMusicPlayer.current.duration));
            padCastTotalTime.current.innerText = `${time.minutes}:${time.seconds}`;
            padCastTotalTime.current.setAttribute("datetime", `${time.minutes}m ${time.seconds}s`);
            haspadCastTotalTime.current = true;
        }
        const time = formatTime(Math.round(customMusicPlayer.current.currentTime));

        passedTime.current.innerText = `${time.minutes}:${time.seconds}`;
        passedTime.current.setAttribute("datetime", `${time.minutes}m ${time.seconds}s`);

        let psTime = customMusicPlayer.current.currentTime;
        let duration = customMusicPlayer.current.duration;

        let passedPercent = (psTime * 100) / duration;
        let widthInPx = (podcastProgress.current.offsetWidth * passedPercent) / 100;
        podcastProgressBar.current.style.width = `${widthInPx}px`;
    }
    function togglePlay(){
        if(isPlaying){
            customMusicPlayer.current.pause();
        }else{
            customMusicPlayer.current.play();
        }
        setIsPlaying(!isPlaying);
    }
    function closePlayer(){
        customMusicPlayer.current.pause();
        setPlayer(null);
        setIsPlaying(false);
    }
    function formatTime(timeInSeconds) {
        const result = new Date(timeInSeconds * 1000).toISOString().substr(11, 8);
        return {
          minutes: result.substr(3, 2),
          seconds: result.substr(6, 2),
        };
    }
    function changeTimeHandler(e) {
        if (!customMusicPlayer.current.duration) {
          return;
        }
        let posX;
        if (e.type == "touchend") {
          var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
          posX = touch.pageX;
        } else if (e.type === "click") {
          posX = e.clientX;
        }
        posX = posX - podcastProgress.current.offsetLeft;
        let volumePercent = (posX * 100) / podcastProgress.current.offsetWidth;
        let newTime = (volumePercent * customMusicPlayer.current.duration) / 100;
        customMusicPlayer.current.currentTime = Math.round(newTime);
    }
    function changeVolumeHandler(e) {
        let posX;
        if (e.type == "touchend") {
          var touch = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
          posX = touch.pageX;
        } else if (e.type === "click") {
          posX = e.clientX;
        }
        posX = posX - volumeDiv.current.offsetLeft;
        let volumePercent = (posX * 100) / volumeDiv.current.offsetWidth;
        customMusicPlayer.current.volume = volumePercent / 100;
        let widthInPx = (volumeDiv.current.offsetWidth * volumePercent) / 100;
        volumeProgress.current.style.width = `${widthInPx}px`;
    }
    function changeTime(newValue) {
        if (!customMusicPlayer.current.duration) {
            return;
        }
        const currentTime = customMusicPlayer.current.currentTime;
        if (newValue > 0 && newValue + currentTime < customMusicPlayer.current.duration) {
            customMusicPlayer.current.currentTime = currentTime + newValue;
        } else if (newValue < 0 && currentTime - newValue > 5) {
            customMusicPlayer.current.currentTime = currentTime + newValue;
        }
    }
    function musicEndHandler() {  
        customMusicPlayer.current.pause();
        const allData = document.querySelectorAll(".playLogoSpan");
        const repeateMusicBtn = document.querySelector(".repeateMusicBtn");
        if (repeateMusicBtn.classList.contains("repeatOn")) {
          customMusicPlayer.current.currentTime = 0;
          customMusicPlayer.current.play();
        } else if (allData.length > 1) {
          const tempIndex = currentPodcastIndex + 1;
          if (tempIndex < allData.length) {
              setCurrentPodcastIndex(tempIndex);
              setPlayer({source:allData[tempIndex].dataset.url ,cover : allData[tempIndex].dataset.cover , title: allData[tempIndex].dataset.title});
              haspadCastTotalTime.current = false;
        } else {
              setCurrentPodcastIndex(0);
              setPlayer({source:allData[0].url ,data:allData[0]});
              haspadCastTotalTime.current = false;
          }
        } else {
          customMusicPlayer.current.currentTime = 0;
          setIsPlaying(false);
          haspadCastTotalTime.current = false;
        }
    }
    return <>
        <section className={player ? "flex flexCol playerSection rtl show" : "flex flexCol playerSection rtl"}>
            <img src={player && player.cover} alt="" className="musicInfoImg"/>
            <p className="faFont textSize3 podcastTitle">{player && player.title}</p>
            <p className="faFont textSize9 textClr3 podcastDesc">
                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای 
            </p>
            <div className="flex podcastBtnMainDiv">
                <div className="flex podcastTimeMainDiv">
                    <div className="podcastProgress flex flexCol">
                        <div className="podcastProgressBar"></div>
                    </div>
                    <p className="faFont textSize4 padCastTotalTime"></p>
                    <p className="faFont textSize4 passedTime"></p>
                </div>
                <div className="flex playBtnDiv alignCenter justifyBetween">
                    <button className="btn flex flexCenter forwardBtn" onClick={()=>changeTime(5)}>
                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M19.4807 7.08981C19.2207 6.76981 18.7507 6.71977 18.4307 6.97977C18.1107 7.23977 18.0608 7.70981 18.3208 8.02981C19.4508 9.42981 20.0808 11.0898 20.1408 12.8298C20.3008 17.3098 16.7807 21.0898 12.2907 21.2398C7.81073 21.3998 4.03076 17.8798 3.88076 13.3898C3.73076 8.8998 7.24073 5.12977 11.7307 4.97977C12.3007 4.95977 12.9007 5.0098 13.5507 5.1398C13.5907 5.1498 13.6307 5.1398 13.6707 5.1398C13.7707 5.1898 13.8908 5.21976 14.0008 5.21976C14.1708 5.21976 14.3307 5.16977 14.4707 5.04977C14.7907 4.78977 14.8408 4.31978 14.5908 3.99978L12.6107 1.52981C12.3507 1.20981 11.8807 1.14976 11.5607 1.40976C11.2407 1.66976 11.1908 2.13981 11.4408 2.45981L12.2708 3.48978C12.0808 3.47978 11.8808 3.46977 11.6908 3.47977C6.38075 3.65977 2.21077 8.1398 2.40077 13.4498C2.59077 18.7598 7.06075 22.9298 12.3707 22.7398C17.6807 22.5498 21.8507 18.0798 21.6607 12.7698C21.5607 10.7098 20.8207 8.73981 19.4807 7.08981Z" fill="currentColor"/>
                            <path d="M12.3806 16.9198H10.0906C9.68065 16.9198 9.34065 16.5798 9.34065 16.1698C9.34065 15.7598 9.68065 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85065 13.8598 9.6206 13.7398 9.4806 13.5498C9.3406 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="currentColor"/>
                        </svg>
                    </button>
                    <button className={isPlaying ? "playBtn btn flex flexCenter play":"playBtn btn flex flexCenter"} onClick={()=>togglePlay()}>
                        <span className="playSvg">
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M133 440a35.37 35.37 0 0 1-17.5-4.67c-12-6.8-19.46-20-19.46-34.33V111c0-14.37 7.46-27.53 19.46-34.33a35.13 35.13 0 0 1 35.77.45l247.85 148.36a36 36 0 0 1 0 61l-247.89 148.4A35.5 35.5 0 0 1 133 440z"></path></svg>
                        </span>
                        <span className="pauseSvg">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"></path></svg>
                        </span>
                    </button>
                    <button className="btn flex flexCenter backwardBtn" onClick={()=>changeTime(-5)}>
                        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3806 16.9198H10.0906C9.68061 16.9198 9.34061 16.5798 9.34061 16.1698C9.34061 15.7598 9.68061 15.4198 10.0906 15.4198H12.3806C12.8106 15.4198 13.1606 15.0698 13.1606 14.6398C13.1606 14.2098 12.8106 13.8598 12.3806 13.8598H10.0906C9.85061 13.8598 9.62062 13.7398 9.48062 13.5498C9.34062 13.3598 9.30062 13.0998 9.38062 12.8698L10.1406 10.5798C10.2406 10.2698 10.5306 10.0698 10.8506 10.0698H13.9106C14.3206 10.0698 14.6606 10.4098 14.6606 10.8198C14.6606 11.2298 14.3206 11.5698 13.9106 11.5698H11.3906L11.1306 12.3598H12.3806C13.6406 12.3598 14.6606 13.3798 14.6606 14.6398C14.6606 15.8998 13.6406 16.9198 12.3806 16.9198Z" fill="currentColor"/>
                            <path opacity="0.4" d="M11.9998 3.47994C11.9198 3.47994 11.8399 3.48995 11.7599 3.48995L12.5798 2.46993C12.8398 2.14993 12.7898 1.66994 12.4598 1.41994C12.1298 1.16994 11.6699 1.20994 11.4099 1.53994L9.42984 4.00997C9.41984 4.01997 9.41985 4.03995 9.40985 4.04995C9.37985 4.08995 9.35984 4.13996 9.33984 4.18996C9.31984 4.22996 9.29986 4.26997 9.28986 4.31997C9.27986 4.36997 9.27985 4.40998 9.27985 4.45998C9.27985 4.50998 9.27985 4.55994 9.27985 4.60994C9.27985 4.62994 9.27985 4.63993 9.27985 4.65993C9.28985 4.68993 9.30985 4.71996 9.31985 4.74996C9.33985 4.79996 9.35985 4.83997 9.37985 4.88997C9.40985 4.92997 9.43985 4.96996 9.46985 4.99996C9.48985 5.02996 9.50986 5.05997 9.53986 5.07997C9.54986 5.08997 9.56984 5.09994 9.58984 5.10994C9.61984 5.12994 9.64986 5.13993 9.66986 5.15993C9.71986 5.18993 9.77984 5.20993 9.83984 5.21993C9.86984 5.22993 9.89984 5.22995 9.92984 5.23995C9.95984 5.23995 9.97986 5.24996 10.0099 5.24996C10.0399 5.24996 10.0598 5.23994 10.0798 5.22994C10.1098 5.22994 10.1399 5.23994 10.1699 5.22994C10.8099 5.07994 11.4099 5.00997 11.9799 5.00997C16.4699 5.00997 20.1198 8.65998 20.1198 13.15C20.1198 17.64 16.4699 21.2899 11.9799 21.2899C7.48986 21.2899 3.83984 17.64 3.83984 13.15C3.83984 11.41 4.40984 9.72994 5.48984 8.28994C5.73984 7.95994 5.66984 7.48995 5.33984 7.23995C5.00984 6.98995 4.53986 7.05997 4.28986 7.38997C3.00986 9.08997 2.33984 11.08 2.33984 13.15C2.33984 18.46 6.65986 22.7899 11.9799 22.7899C17.2999 22.7899 21.6198 18.47 21.6198 13.15C21.6198 7.82998 17.3098 3.47994 11.9998 3.47994Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div className="flex alignCenter volumeMainDiv justifyBetween">
                    <button className="musicPlayerCloser btn flex flexCenter" onClick={()=>{closePlayer()}}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path></svg>
                    </button>
                    <button className={repeatMode ? "btn flex flexCenter repeateMusicBtn repeatOn" : "btn flex flexCenter repeateMusicBtn"} onClick={()=>setRepeatMode(!repeatMode)}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M21 6h-5v2h4v9H4V8h5v3l5-4-5-4v3H3a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"></path></svg>
                    </button>
                    <div className="flex gap5 volumeSection alignCenter">
                        <div className="flex alignCenter">
                            <div className="flex volumeDiv flexCol">
                                <div className="volumeProgress"></div>
                            </div>
                            <button className="btn flex flexCenter speakerIcon">
                                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 3.75a.75.75 0 00-1.264-.546L4.703 7H3.167a.75.75 0 00-.7.48A6.985 6.985 0 002 10c0 .887.165 1.737.468 2.52.111.29.39.48.7.48h1.535l4.033 3.796A.75.75 0 0010 16.25V3.75zM15.95 5.05a.75.75 0 00-1.06 1.061 5.5 5.5 0 010 7.778.75.75 0 001.06 1.06 7 7 0 000-9.899z"></path><path d="M13.829 7.172a.75.75 0 00-1.061 1.06 2.5 2.5 0 010 3.536.75.75 0 001.06 1.06 4 4 0 000-5.656z"></path></svg>
                                <span className="hideText">mute</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <audio className="customMusicPlayer" id="customMusicPlayer" src={player ? player.src : ""} autoPlay>
                Your browser does not support the audio element.
            </audio>
        </section>
    </>
}